import jwt_decode from "jwt-decode";
import { isDemo } from '../constants/config'

export default (to, from, next) => {

  let userObj = localStorage.getItem('user');
  let nowTime = Date.now();
  let tokenExpTime;

	if (isDemo)
    	next()
    
  	if (userObj != null && userObj.length > 0) {
      userObj = JSON.parse(userObj);
    	// verify with firebase or jwt
      //si tiene token obtener fecha
      tokenExpTime = new Date(jwt_decode(userObj.token).exp * 1000);
     
      if(tokenExpTime > nowTime){
        //Todavía está vigente
        
        if(to.path === "/login"){
          next("/plataforma")
        }else{
          next()  
        }
      }else{
        //Ya no está vigente
        localStorage.removeItem('user')
        if(to.path === "/login"){
          next()
        }else{
          next('/login')
        }
      }

  	} else {
      
  		localStorage.removeItem('user')

  		if(to.path === "/login"){
  			next()
  		}else{
  			next('/login')
  		}
  	}
}
