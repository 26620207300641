//Modulo por Middas

import axios from 'axios';
import jwt_decode from "jwt-decode";

function tokenValid(token){
	let result = false;

	try{
		let tokenExpTime = null;
		let nowTime = Date.now();
		let jwToken = jwt_decode(token);

		if(jwToken.exp){
			tokenExpTime = new Date(jwToken.exp * 1000);
			
			if(tokenExpTime > nowTime){
				result = true;
			}
		}
	}catch(error){
		console.log("Token invalido", error)
	}
	
	return result;
}

function sendRequest(payload, method, data = {}){
	let requestPromise = null;
	let headersObj;

	if(payload.headers != null){
		headersObj = payload.headers;	
	}else{
		headersObj = {};
	}

	headersObj.Authorization = "Bearer " + payload.token;
	
	if(tokenValid(payload.token)){

		requestPromise = axios.request({
			url: payload.endpoint,
			method: method,
			//headers: { Authorization: "Bearer " + payload.token},
			headers: headersObj,
			data: data
		});

	}else{
		requestPromise = new Promise(()=> {
			throw Error("La sesión caducó");
		})
	}

	return requestPromise;
}

function externalRequest(payload, data = {}){
	let requestPromise = null;
	let headersObj;

	if(payload.headers != null){
		headersObj = payload.headers;	
	}else{
		headersObj = {};
	}	

	requestPromise = axios.request({
		url: payload.endpoint,
		method: payload.method,
		//headers: { Authorization: "Bearer " + payload.token},
		headers: headersObj,
		data: data
	});	

	return requestPromise;
}


export default{
	actions: {
		/* El parametro payload es un objeto con los campos Endpoint y Token, donde endpoint es la ruta de la api y token es el token de sesión*/
		getAll({commit}, payload){
			return sendRequest(payload, "get");
		},

		getOne({commit}, payload){
			return sendRequest(payload, "get");
		},

		create({commit}, payload){
			return sendRequest(payload, "post", payload.data);
		},

		update({commit}, payload){
			return sendRequest(payload, "put", payload.data);
		},

		delete({commit}, payload){
			return sendRequest(payload, "delete");
		},

		external({commit}, payload){
			return externalRequest(payload, payload.data);
		}
		
	}
}